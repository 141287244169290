.k-edit-form
{
    margin: 0;
    padding: 0;
}

.k-window > div.k-popup-edit-form
{
    padding: 1em 0;
}

.k-grid-edit-row .k-edit-form td
{
    border-bottom-width: 0;
}

.k-edit-form-container
{
    position: relative;
    width: 400px;
}

.k-edit-label,
.k-edit-form-container .editor-label
{
    float: left;
    clear: both;
    width: 30%;
    padding: .4em 0 1em;
    margin-left: 2%;
    text-align: right;
}

.k-edit-field,
.k-edit-form-container .editor-field
{
    float: right;
    clear: right;
    width: 60%;
    margin-right: 2%;
    padding: 0 0 .6em;
}

.k-edit-field > input[type="checkbox"],
.k-edit-field > input[type="radio"]
{
    margin-top: .4em;
}

.k-edit-form-container .k-button
{
    margin: 0 .16em;
}

.k-edit-field > input[type="checkbox"]:first-child,
.k-edit-field > input[type="radio"]:first-child,
.k-edit-field > label:first-child > input[type="checkbox"],
.k-edit-field > .k-button:first-child
{
    margin-left: 0;
}

.k-edit-form-container .k-edit-buttons
{
    clear: both;
    text-align: right;
    border-width: 1px 0 0;
    border-style: solid;
    position: relative;
    bottom: -1em;
    padding: .6em;
}

