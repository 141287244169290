.transition(@transition) {
    -webkit-transition: ~"@{transition}";
    -moz-transition: ~"@{transition}";
    -o-transition: ~"@{transition}";
    transition: ~"@{transition}";
}

.flexbox() {
    display: ~"-moz-box";
    display: ~"-webkit-box";
    display: ~"-webkit-flex";
    display: ~"-ms-flexbox";
    display: flex;
}

.flexbox(@type) {
    display: ~"-moz-@{type}-box";
    display: ~"-webkit-@{type}-box";
    display: ~"-webkit-@{type}-flex";
    display: ~"-ms-@{type}-flexbox";
    display: ~"@{type}-flex";
}

.animation(...) {
    animation: @arguments;
    -moz-animation: @arguments;
    -webkit-animation: @arguments;
}

.animation-delay(...) {
    animation-delay: @arguments;
    -moz-animation-delay: @arguments;
    -webkit-animation-delay: @arguments;
}

.box-orient(@direction) {
    -moz-box-orient: @direction;
    -webkit-box-orient: @direction;
}

.box-direction(@direction) {
    -moz-box-direction: @direction;
    -webkit-box-direction: @direction;
}

.flex-direction(@direction) {
    -webkit-flex-direction: @direction;
    -ms-flex-direction: @direction;
    flex-direction: @direction;
}

.flex-wrap(@wrap) {
    -webkit-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
}

.flex(@amount) {
    -moz-box-flex: @amount;
    -webkit-box-flex: @amount;
    -webkit-flex: @amount;
    -ms-flex: @amount;
    flex: @amount;
}

.flex-align(@alignment) {
    -moz-box-align: @alignment;
    -webkit-box-align: @alignment;
    -ms-flex-align: @alignment;
    flex-align: @alignment;
}

.flex-align-items(@alignment) {
    -webkit-align-items: @alignment;
    align-items: @alignment;
}

.flex-align-content(@alignment) {
    -webkit-align-content: @alignment;
    align-content: @alignment;
}

.flex-pack(@pack) {
    -moz-box-pack: @pack;
    -webkit-box-pack: @pack;
    -webkit-flex-pack: @pack;
    -ms-flex-pack: @pack;
    flex-pack: @pack;
}

.text-size-adjust(...) {
    -webkit-text-size-adjust: @arguments;
    -moz-text-size-adjust: @arguments;
    text-size-adjust: @arguments;
}

.prohibit-selection() {
    -khtml-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

.disable-high-contrast() {
    -ms-high-contrast-adjust: none;
}

.box-sizing(@sizing) {
    -webkit-box-sizing: @sizing;
    -moz-box-sizing: @sizing;
    box-sizing: @sizing;
}

.transform(...) {
    -webkit-transform: @arguments;
    -moz-transform: @arguments;
    -ms-transform: @arguments;
    -o-transform: @arguments;
    transform: @arguments;
}

.transform-origin(...) {
    -webkit-transform-origin: @arguments;
    -moz-transform-origin: @arguments;
    -ms-transform-origin: @arguments;
    -o-transform-origin: @arguments;
    transform-origin: @arguments;
}

.transform-style(...) {
    -webkit-transform-origin: @arguments;
    -moz-transform-origin: @arguments;
    transform-style: @arguments;
}

.transition(...) {
    -webkit-transition: @arguments;
    -moz-transition: @arguments;
    -ms-transition: @arguments;
    -o-transition: @arguments;
    transition: @arguments;
}
