/* PanelBar */

.k-panelbar
{
    zoom: 1;
}

.k-panelbar > .k-item,
.k-panel > .k-item
{
    list-style-type: none;
    display: block;
    border-width: 0;
    margin: 0;
    zoom: 1;

    border-radius: 0;
}

.k-panelbar .k-image,
.k-panelbar .k-sprite
{
    float: left;
    margin-top: 4px;
    margin-right: 5px;
    vertical-align: middle;
}

.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link
{
    display: block;
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 0 1em;
    line-height: 2.34em;
    text-decoration: none;
    zoom: 1;
}

.k-panelbar-expand,
.k-panelbar-collapse
{
    position: absolute;
    top: 50%;
    right: 4px;
    margin-top: -8px;
}

.k-panelbar .k-panel,
.k-panelbar .k-content
{
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    margin: 0;
    padding: 0;
    zoom: 1;
}

.k-panel > .k-item > .k-link
{
    border-bottom: 0;
    font-size: .95em;
    line-height: 2.2;
}

.k-panel .k-panel > .k-item > .k-link
{
    padding-left: 2em;
}

.k-panelbar .k-i-seek-e .k-link
{
    border-bottom: 0;
}

.k-panel .k-panel
{
    border-bottom: 0;
}


