/* IE7 inline-block behavior */

.k-ie7 .k-button,

.k-ie7 .k-grid-filter,
.k-ie7 .k-header-column-menu,
.k-ie7 .k-grid .k-pager-numbers,
.k-ie7 .k-grid .k-status-text,
.k-ie7 .k-pager-numbers .k-link,
.k-ie7 .k-pager-numbers span,
.k-ie7 .k-pager-numbers input,
.k-ie7 .k-grouping-row p,
.k-ie7 .k-grouping-header a,
.k-ie7 .k-grouping-header .k-group-indicator,
.k-ie7 .k-grouping-header .k-link,
.k-ie7 .k-grouping-header .k-button,
.k-ie7 .k-grid-actions,
.k-ie7 .k-edit-label,
.k-ie7 .k-edit-field,
.k-ie7 .k-edit-form-container .editor-label,
.k-ie7 .k-edit-form-container .editor-field,

.k-ie7 .k-combobox,
.k-ie7 .k-dropdown,
.k-ie7 .k-selectbox,
.k-ie7 .k-picker-wrap .k-select,
.k-ie7 .k-dropdown-wrap .k-select,

.k-ie7 .k-numerictextbox,

.k-ie7 .k-timepicker,
.k-ie7 .k-datepicker,
.k-ie7 .k-datetimepicker,
.k-ie7 .k-colorpicker,
.k-ie7 .k-calendar,
.k-ie7 .k-calendar .k-nav-fast,

.k-ie7 .k-treeview .k-icon,
.k-ie7 .k-treeview .k-image,
.k-ie7 .k-treeview .k-sprite,
.k-ie7 .k-treeview .k-in,

.k-ie7 .k-colorpicker,
.k-ie7 .k-colorpicker .k-tool-icon,
.k-ie7 .k-palette.k-reset,

.k-ie7 .k-editor-dialog .k-button,

.k-ie7 .k-form-text-row label,

.k-ie7 .k-tabstrip-items .k-item,
.k-ie7 .k-tabstrip-items .k-link,

.k-ie7 .k-slider-horizontal,

.k-ie7 .k-splitbar-draggable-vertical .k-resize-handle,
.k-ie7 .k-splitbar-draggable-horizontal .k-resize-handle,

.k-ie7 .t-filename,

.k-ie7 div.k-window,
.k-ie7 .k-window-titlebar .k-window-action,

.k-ie7 .k-scheduler-toolbar > ul > li,
.k-ie7 .k-scheduler-footer > ul > li,
.k-ie7 .k-scheduler-toolbar > ul > li,
.k-ie7 .k-scheduler-footer > ul > li,

.k-ie7 .k-event:hover .k-event-delete,
.k-ie7 tr:hover > td > .k-task .k-event-delete,

.k-ie7 .k-progressbar,
.k-ie7 .k-progressbar-horizontal .k-item,
.k-ie7 .k-progress-status,

.k-ie7 .k-grid-header-locked,
.k-ie7 .k-grid-content-locked,
.k-ie7 .k-grid-header-locked + .k-grid-header-wrap,
.k-ie7 .k-grid-content-locked + .k-grid-content,
.k-ie7 .k-grid-footer-locked,

.k-ie7 .k-gantt-layout,
.k-ie7 .k-gantt-toolbar > ul > li,
.k-ie7 .k-gantt-toolbar .k-link,
.k-ie7 .k-task-summary,
.k-ie7 .k-task-actions:first-child > .k-link,
.k-ie7 .k-task-wrap:hover .k-task-delete,
.k-ie7 .k-task-wrap-active .k-task-delete

{ display: inline; zoom: 1; }

.k-ie7 .k-treeview .k-item,
.k-ie7 .k-treeview .k-group
{
    zoom: 1;
}

.k-ie7 .k-edit-field > .k-textbox
{
    text-indent: 0;
}