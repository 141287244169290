/* Slider */

div.k-slider
{
    position: relative;
    border-width: 0;
    background-color: transparent;

    .prohibit-selection;
}

.k-slider-vertical
{
    width: 26px;
    height: 200px; /* default height */
}

.k-slider-horizontal
{
    display: inline-block;
    width: 200px; /* default width */
    height: 26px;
}

.k-slider-wrap
{
    width: 100%;
    height: 100%;
}

.k-slider .k-button,
.k-grid .k-slider .k-button
{
    position: absolute;
    top: 0;
    width: 24px;
    min-width: 0;
    height: 24px;
    margin: 0;
    padding: 0;
    outline: 0;
}

.k-slider .k-button .k-icon
{
    margin-top: 3px;
    vertical-align: top;
}

.k-state-disabled .k-slider-wrap
{
    filter: alpha(opacity=60);
    opacity: .6;
}

.k-state-disabled .k-slider-wrap .k-slider-items
{
    color: #333;
}

.k-slider .k-button-decrease
{
    left: 0;
}

.k-slider-vertical .k-button-decrease,
.k-grid .k-slider-vertical .k-button-decrease
{
    top: auto;
    bottom: 0;
}

.k-slider .k-button-increase
{
    right: 0;
}

.k-slider .k-icon,
.k-slider-track,
.k-slider .k-tick
{
    cursor: pointer;
}

.k-ie7 .k-slider .k-icon { margin-top: 2px; }

.k-slider-track,
.k-slider-selection{
    position: absolute;
    margin: 0;
    padding: 0;
}

.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection
{
    top: 50%;
    left: 0;
    height: 8px;
    margin-top: -4px;
    background-repeat: repeat-x;
}

.k-slider-horizontal .k-slider-buttons .k-slider-track
{
    left: 34px;
}

.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection
{
    left: 50%;
    bottom: 0;
    width: 8px;
    margin-left: -4px;
    background-repeat: repeat-y;
}

.k-slider-vertical .k-slider-buttons .k-slider-track
{
    bottom: 34px;
}

.k-draghandle
{
    position: absolute;
    background-repeat: no-repeat;
    background-color: transparent;
    text-indent: -3333px;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    outline: 0;
}

.k-slider-horizontal .k-draghandle
{
    top: -4px;
    width: 13px;
    height: 14px;
}

.k-slider-vertical .k-draghandle
{
    left: -4px;
    width: 14px;
    height: 13px;
}

.k-slider-buttons .k-slider-items
{
    margin-left: 34px;
}

.k-slider-horizontal .k-slider-items
{
    height: 100%;
}

.k-slider-vertical .k-slider-items
{
    padding-top: 1px;
}

.k-slider-vertical .k-slider-buttons .k-slider-items
{
    padding-top: 0;
}

.k-slider-vertical .k-slider-buttons .k-slider-items
{
    margin: 0;
    padding-top: 35px;
}

.k-slider .k-tick
{
    position: relative;
    margin: 0;
    padding: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
}

.k-slider-horizontal .k-tick
{
    float: left;
    height: 100%;
    text-align: center;
}

/* fixes ticks position and removes spacing between them in IE7 */
.k-ie7 .k-slider-vertical .k-tick { float: left; clear: left; width: 100%; }

.k-slider-horizontal .k-tick { background-position: center -92px; }
.k-slider-horizontal .k-slider-topleft .k-tick { background-position: center -122px; }
.k-slider-horizontal .k-slider-bottomright .k-tick { background-position: center -152px; }

.k-slider-horizontal .k-tick-large { background-position: center -2px; }
.k-slider-horizontal .k-slider-topleft .k-tick-large { background-position: center -32px; }
.k-slider-horizontal .k-slider-bottomright .k-tick-large { background-position: center -62px; }

.k-slider-vertical .k-tick { background-position: -92px center; }
.k-slider-vertical .k-slider-topleft .k-tick { background-position: -122px center; }
.k-slider-vertical .k-slider-bottomright .k-tick { background-position: -152px center; }

.k-slider-vertical .k-tick-large { background-position: -2px center; }
.k-slider-vertical .k-slider-topleft .k-tick-large { background-position: -32px center; }
.k-slider-vertical .k-slider-bottomright .k-tick-large { background-position: -62px center; }

.k-slider-horizontal .k-first { background-position: 0 -92px; }
.k-slider-horizontal .k-tick-large.k-first { background-position: 0 -2px; }
.k-slider-horizontal .k-slider-topleft .k-first { background-position: 0 -122px; }
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first { background-position: 0 -32px; }
.k-slider-horizontal .k-slider-bottomright .k-first { background-position: 0 -152px; }
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first { background-position: 0 -62px; }

.k-slider-horizontal .k-last { background-position: 100% -92px; }
.k-slider-horizontal .k-tick-large.k-last { background-position: 100% -2px; }
.k-slider-horizontal .k-slider-topleft .k-last { background-position: 100% -122px; }
.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last { background-position: 100% -32px; }
.k-slider-horizontal .k-slider-bottomright .k-last { background-position: 100% -152px; }
.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last { background-position: 100% -62px; }

.k-slider-vertical .k-first { background-position: -92px 100%; }
.k-slider-vertical .k-tick-large.k-first { background-position: -2px 100%; }
.k-slider-vertical .k-slider-topleft .k-first { background-position: -122px 100%; }
.k-slider-vertical .k-slider-topleft .k-tick-large.k-first { background-position: -32px 100%; }
.k-slider-vertical .k-slider-bottomright .k-first { background-position: -152px 100%; }
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first { background-position: -62px 100%; }

.k-slider-vertical .k-last { background-position: -92px 0; }
.k-slider-vertical .k-tick-large.k-last { background-position: -2px 0; }
.k-slider-vertical .k-slider-topleft .k-last { background-position: -122px 0; }
.k-slider-vertical .k-slider-topleft .k-tick-large.k-last { background-position: -32px 0; }
.k-slider-vertical .k-slider-bottomright .k-last { background-position: -152px 0; }
.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last { background-position: -62px 0; }

.k-slider-vertical .k-tick
{
    text-align: right;
}

.k-slider-vertical .k-slider-topleft .k-tick
{
    text-align: left;
}

.k-slider .k-label
{
    position: absolute;
    white-space: nowrap;
    font-size: .92em;
}

.k-slider-horizontal .k-label
{
    left: 0;
    width: 100%;
    line-height: 1;
}

.k-slider-horizontal .k-first .k-label
{
    left: -50%;
}

.k-slider-horizontal .k-last .k-label
{
    left: auto;
    right: -50%;
}

.k-slider-horizontal .k-label
{
    bottom: -1.2em;
}

.k-slider-horizontal .k-slider-topleft .k-label
{
    top: -1.2em;
}

.k-slider-vertical .k-label
{
    left: 120%;
    display: block;
    text-align: left;
}

.k-slider-vertical .k-last .k-label
{
    top: -0.5em;
}

.k-slider-vertical .k-first .k-label
{
    bottom: -0.5em;
}

.k-slider-vertical .k-slider-topleft .k-label
{
    left: auto;
    right: 120%;
}

.k-slider-tooltip
{
    top:-4444px; /*prevent window resize in IE8 when appending*/
}


