@input-height: 1.65em; //1.94em
@input-indent: .6em;
@editor-icon-size: 32px;
@menu-link-padding: 1.2em 1.2em 1.1em;
@button-padding: 5px 14px 4px;
@treeview-inner-padding: 0.4167em 0.6667em 0.3333em 0.5833em;
@treeview-inner-margin: 1px 0 1px 0.3333em;
@scheduler-link-padding: .16666em 1.1em;
@grid-header-padding: .75em .2em .8333em 1em;
@icon-positioning: "default";

@import "common/core.less";

.k-widget.k-tabstrip
{
    background-color: transparent;
    background-image: none;
    border-style: none;
    .box-shadow(none);
}

.k-tabstrip .k-tabstrip-items
{
    padding: 0;
}

.k-tabstrip > .k-content,
.k-panelbar .k-tabstrip > .k-content
{
    margin: 0;
}

.k-slider-track
{
    border-width: 1px;
    border-style: solid;
}

.k-grouping-dropclue
{
    margin-top: 10px;
}

.k-grid tr td
{
    border-width: 1px 0 0 1px;
    border-style: solid;
}

.k-slider-horizontal .k-slider-track,
.k-slider-horizontal .k-slider-selection
{
    margin-top: -5px;
}

.k-slider-vertical .k-slider-track,
.k-slider-vertical .k-slider-selection
{
    margin-left: -5px;
}

input.k-textbox {
  //height: 2.43em; // Removed, CJ 5/4
  padding:  0.177em 0;
}

.k-checkbox-label,
.k-radio-label {
  line-height: 1.1em;
  font-weight: normal;
}

.k-radio:checked + .k-radio-label:after {
  width: 8px;
  height: 8px;
  top: 4px;
  left: 4px;
}

.k-checkbox:checked + .k-checkbox-label:after {
  line-height: 1em;
}

.k-link:focus {
  outline: 0;
}

// Menu - CJ 9/10
.k-context-menu.k-menu-vertical > .k-item,
.k-menu .k-menu-group .k-item  {
  > .k-link {
    padding: @menu-link-padding;
    padding-right: 3.8em;
  }
}


.k-autocomplete .k-loading, 
.k-multiselect .k-loading {
  bottom: auto;
  top: 9px;
  right: 4px;
}

.k-multiselect-wrap {
  li {
    margin: 3px 1px;
    line-height: 20px;
  }
  .k-input {
    height: 1.9em;
    line-height: 1.9em;
    text-indent: 0.2em;
  }
}

.k-picker-wrap, 
.k-numeric-wrap, 
.k-dropdown-wrap {
  .k-select {
    //width: 2.2em;
    line-height: (@input-height + .15em);
  }
}

.k-dropdown .k-select, 
.k-selectbox .k-select {
  border-style: solid;
  border-width: 0 0 0 1px;
}

.k-upload-status {
  right: 0;
  top: 0.3em
}

.k-filter-menu {
  padding: 10px;
}

.k-grid-filter:focus, 
.k-grid-filter.k-state-focused {
  outline: 0;
}

// Input's Interval - CJ 5/4
.k-interval {
  display: inline-block;
  line-height: @input-height;
  padding: .177em .4em;
}
