/* Calendar */

.k-calendar
{
    position: relative;
    display: inline-block;
    width: 16.917em;
    overflow: hidden;
}

.k-calendar td,
.k-calendar .k-link
{
    text-decoration: none;
}

.k-calendar .k-action-link
{
    text-decoration: underline;
}

.k-calendar .k-header,
.k-calendar .k-footer
{
    position: relative;
    text-align: center;
    zoom: 1;
}

.k-widget.k-calendar .k-nav-prev,
.k-widget.k-calendar .k-nav-next
{
    position: absolute;
    top: 0.16666em;
    line-height: 1.8333em;
    height: 1.8333em;
}
.k-widget.k-calendar .k-nav-prev { left: 1%; }
.k-widget.k-calendar .k-nav-next { right: 1%; }

.k-calendar .k-content
{
    float:left;
    border-spacing: 0;
    width: 100%;
    height: 14.167em;
    border-width: 0;
    margin: 0;
    table-layout: fixed;
    text-align: right;
    outline: 0;
}

.k-calendar .k-animation-container .k-content
{
    height: 100%;
}

.k-widget.k-calendar .k-nav-fast
{
    display: inline-block;
    width: 75%;
    height: 1.8333em;
    line-height: 1.8333em;
    margin: 0.16666em -0.08333em 0.3333em 0;
}

.k-calendar .k-header .k-icon
{
    vertical-align: middle;
}

.k-calendar .k-header .k-link.k-nav-prev,
.k-calendar .k-header .k-link.k-nav-next
{
    height: 1.8333em;
    width: 1.8333em;
}

.k-calendar th
{
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: .4em .45em .4em .1em;
    font-weight: normal;
    cursor: default;
}

.k-calendar td
{
    padding: 0.08333em;
    cursor: pointer;
}

.k-calendar .k-state-focus
{
    border-style: dotted;
    border-width: 0.08333em;
    padding: 0;
}

.k-calendar .k-content .k-link
{
    display: block;
    overflow: hidden;
    min-height:1.8333em;
    line-height:1.8333em;
    padding: 0 .45em 0 .1em;
}

.k-calendar .k-meta-view .k-link
{
    padding: .25em 0 .3em;
    text-align: center;
}

.k-calendar .k-footer
{
    clear:both;
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-footer > .k-state-disabled
{
    display: block;
    height: 100%;
    padding: .5em 0;
}

.k-calendar .k-nav-today:hover
{
    text-decoration:underline;
}