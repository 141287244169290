/* ComboBox & DropDownList */

span.k-datepicker,
span.k-timepicker,
span.k-datetimepicker,
span.k-colorpicker,
span.k-numerictextbox,
span.k-combobox,
span.k-dropdown,
.k-toolbar .k-split-button
{
    background-image: none;
}

.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-textbox,
.k-toolbar .k-split-button
{
    position: relative;
    display: inline-block;
    width: 12.4em;
    overflow: visible;
    border-width: 0;
    vertical-align: middle;
}

.k-filter-menu .k-combobox,
.k-filter-menu .k-datepicker,
.k-filter-menu .k-timepicker,
.k-filter-menu .k-datetimepicker,
.k-filter-menu .k-numerictextbox,
.k-filter-menu .k-dropdown,
.k-filter-menu .k-textbox
{
    width: 13.2em;
}

.k-autocomplete,
.k-combobox,
.k-datepicker,
.k-timepicker,
.k-datetimepicker,
.k-colorpicker,
.k-numerictextbox,
.k-dropdown,
.k-selectbox,
.k-toolbar .k-split-button
{
    white-space: nowrap;
}

.k-colorpicker,
.k-toolbar .k-split-button
{
    width: auto;
}

.k-datetimepicker
{
    width: 15em;
}

.k-autocomplete,
.k-picker-wrap,
.k-numeric-wrap
{
    position: relative;
    cursor: default;
}

.k-dropdown-wrap
{
    position: relative;
}

.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap
{
    display: block;
}

.k-block,
.k-widget,
.k-grid,
.k-slider,
.k-splitter,
.k-treeview,
.k-panelbar,
.k-content,
.k-header-column-menu
{
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-block,
.k-slider,
.k-splitbar,
.k-calendar,
.k-treeview,
.k-pager-wrap,
.k-grid-header .k-link,
.k-header-column-menu
{
    -webkit-touch-callout: none;
}

.k-popup.k-list-container,
.k-popup.k-calendar-container
{
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 2px;
    border-width: 1px;
    border-style: solid;
}

.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down
{
    border-bottom-width: 0;
    padding-bottom: 1px;
}

.k-list-container .km-scroll-container
{
    padding-bottom: 6px;
}

.k-textbox,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap
{
    border-width: 1px;
    border-style: solid;
    padding: 0 1.9em 0 0;
}

.k-numeric-wrap.k-expand-padding
{
    padding-right: 0;
}

.k-textbox,
.k-autocomplete
{
    padding: 0;
}

.k-textbox.k-space-left
{
    padding-left: 1.9em;
}

.k-textbox.k-space-right
{
    padding-right: 1.9em;
}

.k-textbox .k-icon
{
    top: 50%;
    margin: -8px 0 0;
    position: absolute;
}

.k-space-left .k-icon
{
    left: 3px;
}

.k-space-right .k-icon
{
    right: 3px;
}

.k-autocomplete,
.k-dropdown-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover
{
    .transition("box-shadow .15s ease-out");
}

.k-textbox > input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-combobox .k-input
{
    width: 100%;
    vertical-align: top;
}

.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input
{
    font-family: inherit;
    border-width: 0;
    outline: 0;
}

.k-dropdown .k-input,
.k-selectbox .k-input
{
    background: transparent;
}

.k-ie7 .k-picker-wrap .k-input, .k-ie7 .k-numeric-wrap .k-input, .k-ie7 .k-combobox .k-input { margin: -1px 0; } /* removes excessive spacing */

.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select
{
    position: absolute; /* icon positioning */
    top: 0;
    right: 0;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
}

.k-combobox .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select
{
    border-style: solid;
    border-width: 0 0 0 1px;
    border-color: inherit; /* skin-related, inherit does not work in ie7- */
}

span.k-datetimepicker .k-select,
span.k-datetimepicker .k-select+.k-select
{
    right: 0;
}

.k-textbox > input,
.k-autocomplete .k-input
{
    display: block;
}

.k-combobox .k-icon
{
    /*margin-top: 1px;*/
}

.k-dropdown .k-select,
.k-selectbox .k-select
{
    overflow: hidden;
    border: 0;
    text-decoration: none;
    font: inherit;
    color: inherit;
}

.k-dropdown .k-input,
.k-selectbox .k-input
{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-textbox > input,
.k-autocomplete .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input,
.k-dropdown-wrap .k-input,
.k-selectbox .k-input
{
    height: @input-height;
    line-height: @input-height;
    padding: 0.177em 0;
    text-indent: @input-indent;
    border: 0;
    margin: 0;
}

/* fix missing bottom border on browser zoom in Chrome */
.k-webkit .k-combobox .k-dropdown-wrap:before,
.k-webkit .k-picker-wrap:before,
.k-webkit .k-numeric-wrap:before
{
    content: "\a0";
    display: inline-block;
    width: 0;
    height: @input-height;
    padding-bottom: 0.4em;
}

/* above style breaks NumericTextBox layout due display:block style applied to the input */
.km.root .k-combobox .k-dropdown-wrap:before,
.km.root .k-picker-wrap:before,
.km.root .k-numeric-wrap:before
{
    content: none;
}

.k-combobox .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input
{
    display: inline;
}

.k-ie7 .k-autocomplete .k-input,
.k-ie7 .k-picker-wrap .k-input,
.k-ie7 .k-numeric-wrap .k-input,
.k-ie7 .k-dropdown-wrap .k-input,
.k-ie7 .k-selectbox .k-input
{
    text-indent: 0;
}

.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select
{
    min-height: @input-height;
    line-height: (@input-height + .35em);
    vertical-align: middle;
    -moz-box-sizing: border-box;
    text-align: center;
    width: 1.9em;
    height: 100%;
}

.k-numeric-wrap .k-select
{
    padding: 0;
}

body .k-datetimepicker .k-select
{
    border-radius: 0;
}

.k-ie7 .k-picker-wrap .k-icon,
.k-ie7 .k-dropdown-wrap .k-icon
{
    line-height: 2em;
    font-size: 1em;
    padding-top: 16px;
    height: 0;
}

.k-combobox .k-icon,
.k-dropdown,
.k-selectbox .k-icon
{
    cursor: pointer;
}

.k-popup
{
    border-style: solid;
    border-width: 1px;
}

.k-popup .k-item
{
    cursor: default;
}

.k-popup .k-calendar
{
    border: 0;
}

.k-list
{
    height: 100%;
}

.k-popup .k-list .k-item,
.k-fieldselector .k-list .k-item
{
    padding: 1px 5px 1px 5px;
    line-height: 1.8em;
    min-height: 1.8em;
}

.k-overflow-container .k-item
{
    padding: 1px;
}

.k-overflow-container > .k-state-disabled .k-button,
.k-overflow-container .k-button.k-state-disabled,
.k-overflow-container .k-button.k-state-disabled:hover
{
    border: 0 ;
    background: none;
}

.k-popup .k-list .k-state-hover,
.k-popup .k-list .k-state-focused,
.k-popup .k-list .k-state-selected,
.k-overflow-container .k-state-hover,
.k-overflow-container .k-state-focused,
.k-overflow-container .k-state-selected,
.k-fieldselector .k-list .k-item
{
    padding: 0 4px;
    border-width: 1px;
    border-style: solid;
}

.k-list-filter
{
    position: relative;
}

.k-list-filter > .k-textbox
{
    padding-right: 20px;
    width: 100%;
}

.k-list-filter > .k-icon
{
    position: absolute;
    right: 4px;
    top: 3px;
}

/* MultiSelect */

.k-multiselect-wrap
{
    position: relative;
    border-width: 0px;
    border-style: solid;
    border-radius: 4px;
    border-color: #C5C5C5;
    background-color: #FFF;
    min-height: 2.04em;
}

.k-multiselect-wrap .k-input
{
    background-color: transparent;
    height: 1.31em;
    line-height: 1.31em;
    padding: 0.18em 0;
    text-indent: @input-indent;
    border: 0;
    margin: 1px 0 0;
    float: left;
}

.k-multiselect-wrap li
{
    margin: 1px 0 1px 1px;
    padding: .1em .15em .1em .4em;
    line-height: (@input-height - .15);
    float: left;
}

.k-autocomplete .k-loading,
.k-multiselect .k-loading
{
    position: absolute;
    right: 3px;
    bottom: 4px;
}

.k-multiselect .k-loading-hidden
{
    visibility: hidden;
}

/* Date/Time Pickers */

.k-datetimepicker .k-picker-wrap
{
    padding-right: 3.8em;
}

.k-datetimepicker .k-select
{
    width: 3.8em;
}

.k-datetimepicker .k-picker-wrap .k-icon
{
    margin: 0 2px;
}

.k-picker-wrap .k-icon
{
    cursor: pointer;
}

.k-button,
.k-textbox,
.k-timepicker,
.k-datepicker,
.k-datetimepicker
{
    display: inline-block;
    vertical-align: middle;
}

.k-picker-wrap .k-input
{
    margin: 0;
}

.k-time-popup .k-item
{
    padding: 1px 3px;
}

/* inputs */

.k-input
{
    padding: 0.25em 0;
}

.k-input,
.k-textbox > input
{
    outline: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.k-textbox
{
    outline: 0;
    padding: 2px .3em;
    line-height: 1.6em;
}

input.k-textbox
{
    height: (@input-height + 0.48em);
    text-indent: @input-indent;
}

.k-ie input.k-textbox
{
    text-indent: (@input-indent / 2);
}

.k-ff input.k-textbox
{
    height: 2.17em;
}

.k-ie7 input.k-textbox { line-height: 1.72em; height: 1.72em; text-indent: @input-indent; }

textarea.k-textbox
{
    height: auto;
}

.k-ie7 .k-textbox
{
    padding: 1px 0;
    text-indent: 0;
}

/* NumericTextBox */

span.k-numerictextbox
{
    background-color: transparent;
}

.k-numerictextbox .k-input /* alignment of arrows in WebKit */
{
    margin: 0;
}

.k-numerictextbox .k-link
{
    display: block;
    height: 1em;
    line-height: 1em;
    vertical-align: middle;
    border-width: 0;
    padding: 0;
}

.k-numerictextbox .k-icon
{
    height: 11px;
}

.k-numeric-wrap .k-input::-webkit-inner-spin-button
{
    -webkit-appearance: none;
}

/* ColorPicker */

.k-colorpicker
{
    .k-picker-wrap {
        line-height: 2em;
    }

    .k-selected-color {
        vertical-align: top;
        line-height: 0;
        display: inline-block;
        height: 2em;
        width: 2em;
    }
    .k-tool-icon {
        position: relative;
        top: -2px;
        display: inline-block;
        padding: 3px 3px 2px;
        font-size: 0;
        line-height: 0;
        margin-right: 3px;
        margin-left: 2px;
        margin-bottom: 3px;
        background-repeat: no-repeat;
        vertical-align: middle;
        width: @grid-size;
        height: @grid-size;
        .disable-high-contrast();
        .k-selected-color {
            display: block;
            height: 3px;
            width: @grid-size;
            position: absolute;
            left: 3px;
            bottom: -3px;
            border-radius: 0 !important;
        }
    }
    .k-icon {
        cursor: pointer;
    }
}

.box-shadow(...) {
    -webkit-box-shadow: @arguments;
    box-shadow: @arguments;
}

.k-disabled-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.k-colorpalette
{
    position: relative;
    line-height: 0;
    border-width: 0;
    display: inline-block;

    .k-palette {
        border-collapse: collapse;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .k-item {
        width: 14px;
        height: 14px;
        overflow: hidden;
        .disable-high-contrast();

        &.k-state-selected {
            z-index: 100;
            background: transparent;
            box-shadow: 0 1px 4px #000, inset 0 0 3px #fff;
            position: relative;
        }
    }
}

.k-flatcolorpicker
{
    position: relative;
    display: inline-block;
    width: 250px;
    padding-bottom: 5px;

    div& {
        background-color: transparent;
        background-image: none;
    }

    .k-selected-color {
        background-image: url("textures/transtexture.png");
        background-position: 50% 50%;
        text-align: right;

        input.k-color-value {
            font-family: Consolas,"Ubuntu Mono","Lucida Console","Courier New",monospace;
            padding: .75em .3em .65em 1em;
            border: 0;
            margin: 0;
            width: 70%;
        }
    }

    .k-hsv-rectangle {
        position: relative;
        .prohibit-selection;
        -ms-touch-action: pinch-zoom double-tap-zoom;
        .k-draghandle {
            cursor: pointer;
            position: absolute;
            z-index: 10;
            left: 50%;
            top: 50%;
            width: 8px;
            height: 8px;
            border: 1px solid #eee;
            margin-left: -5px;
            margin-top: -5px;
            border-radius: 6px;
            .box-shadow(0, 1px, 2px, #444);
            background: transparent;
            &:hover, &:focus {
                background: transparent;
                border-color: #fff;
                .box-shadow(0 1px 5px #000);
            }
        }
        &.k-dragging, &.k-dragging * {
            cursor: none;
        }
    }

    .k-slider-horizontal {
        height: 20px;
        width: 90%;
        margin: 0 5%;
        .k-slider-track {
            .box-shadow(~"0 1px 0 #fff, 0 -1px 0 #999");
        }
    }

    .k-hue-slider, .k-transparency-slider {
        display: block;
        .k-slider-selection {
            background: transparent;
        }
        .k-draghandle {
            background: transparent;
            border: 3px solid #eee;
            margin-top: 1px;
            height: 8px;
            width: 8px;
            .box-shadow(0 1px 4px #444);
            &:hover, &:focus {
                background: transparent;
                border-color: #fff;
                .box-shadow(0 1px 5px #000);
                border-width: 2px;
                padding: 1px;
            }
        }
    }

    .k-hue-slider {
        .k-slider-track {
            background: -moz-linear-gradient(left,  #ff0000 0%, #ffff00 16%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 84%, #ff0004 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ff0000), color-stop(16%,#ffff00), color-stop(33%,#00ff00), color-stop(50%,#00ffff), color-stop(67%,#0000ff), color-stop(84%,#ff00ff), color-stop(100%,#ff0004)); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  #ff0000 0%,#ffff00 16%,#00ff00 33%,#00ffff 50%,#0000ff 67%,#ff00ff 84%,#ff0004 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  #ff0000 0%,#ffff00 16%,#00ff00 33%,#00ffff 50%,#0000ff 67%,#ff00ff 84%,#ff0004 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  #ff0000 0%,#ffff00 16%,#00ff00 33%,#00ffff 50%,#0000ff 67%,#ff00ff 84%,#ff0004 100%); /* IE10+ */
            background: linear-gradient(left,  #ff0000 0%,#ffff00 16%,#00ff00 33%,#00ffff 50%,#0000ff 67%,#ff00ff 84%,#ff0004 100%); /* W3C */
        }
    }

    .k-transparency-slider {
        .k-slider-track {
            background-image: url("textures/transparency.png");
            background-size: 100% auto;
            background-position: 100% 50%;
            background-repeat: no-repeat;
        }
    }

    .k-controls {
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: center;
        font-size: 90%;
        .k-button {
            width: 6em;
        }
    }

    .k-hsv-gradient {
        background:
        -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%), /* FF3.6+ */
        -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6+ */

        background:
        -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,1))), /* Chrome,Safari4+ */
        -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */

        background:
        -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%), /* Chrome10+,Safari5.1+ */
        -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */

        background:
        -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%), /* Opera 11.10+ */
        -o-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */

        background:
        -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%), /* IE10+ */
        -ms-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* IE10+ */

        background:
        linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%), /* W3C */
        linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C */

        height: 180px;
        margin-bottom: 5px;
    }
}

.k-ie9 {
    .k-flatcolorpicker {
        .k-hue-slider {
            .k-slider-track {
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjE2JSIgc3RvcC1jb2xvcj0iI2ZmZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjMzJSIgc3RvcC1jb2xvcj0iIzAwZmYwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzAwZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjY3JSIgc3RvcC1jb2xvcj0iIzAwMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijg0JSIgc3RvcC1jb2xvcj0iI2ZmMDBmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjAwMDQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
            }
        }
        .k-hsv-gradient {
            background:
            url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+),
            url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        }
    }
}

.k-ie7, .k-ie8 {
    .k-flatcolorpicker {
        .k-hue-slider {
            .k-slider-track {
                background: url("textures/hue.png") repeat 0 50%;
            }
        }
        .k-transparency-slider {
            .k-slider-track {
                background: url("textures/transparency.png") repeat 0 50%;
            }
        }
        .k-hsv-gradient {
            filter: ~"progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff',endColorstr='#00ffffff',GradientType=1) progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#ff000000',GradientType=0)";
        }
    }
}

