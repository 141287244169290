/* animation classes */

@duration: 350ms;

.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
    .transition(all @duration ease-out);
}

.k-fx {
    position: relative;

    .k-fx-current {
        z-index: 0;
    }

    .k-fx-next {
        z-index: 1;
    }
}

.k-fx-hidden,
.k-fx-hidden * {
    visibility: hidden !important;
}

.k-fx-reverse {
    .k-fx-current {
        z-index: 1;
    }

    .k-fx-next {
        z-index: 0;
    }
}

/* Zoom */

.k-fx-zoom {
    &.k-fx-start .k-fx-next {
        .transform(scale(0)) !important;
    }

    &.k-fx-end .k-fx-next {
        .transform(scale(1)) !important;
    }

    &.k-fx-reverse {
        &.k-fx-start .k-fx-next,
        &.k-fx-end .k-fx-next {
            .transform(scale(1)) !important;
        }

        &.k-fx-start .k-fx-current {
            .transform(scale(1)) !important;
        }

        &.k-fx-end .k-fx-current {
            .transform(scale(0)) !important;
        }
    }
}

/* Fade */
.k-fx-fade {
    &.k-fx-start .k-fx-next {
        will-change: opacity;
        opacity: 0;
    }

    &.k-fx-end .k-fx-next {
        opacity: 1;
    }

    &.k-fx-reverse {
        &.k-fx-start .k-fx-current {
            will-change: opacity;
            opacity: 1;
        }

        &.k-fx-end .k-fx-current {
            opacity: 0;
        }
    }
}

/* Slide */
.k-fx-slide {
    &.k-fx-end .k-fx-next .km-content,
    &.k-fx-end .k-fx-next .km-header,
    &.k-fx-end .k-fx-next .km-footer,
    &.k-fx-end .k-fx-current .km-content,
    &.k-fx-end .k-fx-current .km-header,
    &.k-fx-end .k-fx-current .km-footer
    {
        .transition(all @duration ease-out);
    }

    /* left */
    &.k-fx-start .k-fx-next .km-content {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-start .k-fx-next .km-header,
    &.k-fx-start .k-fx-next .km-footer {
        will-change: opacity;
        opacity: 0;
    }

    &.k-fx-end .k-fx-current .km-content {
        .transform(translatex(-100%));
    }

    &.k-fx-end .k-fx-next .km-header,
    &.k-fx-end .k-fx-next .km-footer {
        opacity: 1;
    }

    /* left reverse */
    &.k-fx-reverse {
        &.k-fx-start .k-fx-current .km-content {
            will-change: transform;
            .transform(translatex(0));
        }

        &.k-fx-end .k-fx-current .km-content {
            .transform(translatex(100%));
        }

        &.k-fx-start .k-fx-next .km-content {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-next .km-content {
            .transform(translatex(0));
        }

        &.k-fx-start .k-fx-current .km-header,
        &.k-fx-start .k-fx-current .km-footer {
            will-change: opacity;
            opacity: 1;
        }

        &.k-fx-start .k-fx-next .km-header,
        &.k-fx-start .k-fx-next .km-footer {
            opacity: 1;
        }

        &.k-fx-end .k-fx-current .km-header,
        &.k-fx-end .k-fx-current .km-footer {
            opacity: 0;
        }

        &.k-fx-end .k-fx-next .km-header,
        &.k-fx-end .k-fx-next .km-footer {
            opacity: 1;
        }
    }

    /* right */
    &.k-fx-right {
        &.k-fx-start .k-fx-next .km-content {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-current .km-content {
            .transform(translatex(100%));
        }

        /* right reverse */
        &.k-fx-reverse {
            &.k-fx-start .k-fx-current .km-content {
                .transform(translatex(0));
            }

            &.k-fx-end .k-fx-current .km-content {
                .transform(translatex(-100%));
            }

            &.k-fx-start .k-fx-next .km-content {
                .transform(translatex(100%));
            }

            &.k-fx-end .k-fx-next .km-content {
                .transform(translatex(0%));
            }
        }
    }
}

/* Tile */
.k-fx-tile {
    /* left */
    &.k-fx-start .k-fx-next {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-end .k-fx-current {
        .transform(translatex(-100%));
    }

    /* left reverse */
    &.k-fx-reverse {
        &.k-fx-start .k-fx-current {
            will-change: transform;
            .transform(translatex(0));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-next {
            .transform(translatex(0));
        }
    }

    /* right */
    &.k-fx-right {
        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        /* right reverse */
        &.k-fx-reverse {
            &.k-fx-start .k-fx-current {
                .transform(translatex(0));
            }

            &.k-fx-end .k-fx-current {
                .transform(translatex(-100%));
            }

            &.k-fx-start .k-fx-next {
                .transform(translatex(100%));
            }

            &.k-fx-end .k-fx-next {
                .transform(translatex(0%));
            }
        }
    }
}

/* Tile */
.k-fx-tile {
    /* left */
    &.k-fx-start .k-fx-next {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-end .k-fx-current {
        .transform(translatex(-100%));
    }

    /* left reverse */
    &.k-fx-reverse {
        &.k-fx-start .k-fx-current {
            will-change: transform;
            .transform(translatex(0));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-next {
            .transform(translatex(0));
        }
    }

    /* right */
    &.k-fx-right {
        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        /* right reverse */
        &.k-fx-reverse {
            &.k-fx-start .k-fx-current {
                .transform(translatex(0));
            }

            &.k-fx-end .k-fx-current {
                .transform(translatex(-100%));
            }

            &.k-fx-start .k-fx-next {
                .transform(translatex(100%));
            }

            &.k-fx-end .k-fx-next {
                .transform(translatex(0%));
            }
        }
    }
}

/* Overlay */
.k-fx.k-fx-overlay {
    &.k-fx-start .k-fx-next,
    &.k-fx-left.k-fx-start .k-fx-next {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-right.k-fx-start .k-fx-next {
        .transform(translatex(-100%));
    }

    &.k-fx-up.k-fx-start .k-fx-next {
        .transform(translatey(100%));
    }

    &.k-fx-down.k-fx-start .k-fx-next {
        .transform(translatey(-100%));
    }

    &.k-fx-reverse {
        &.k-fx-start .k-fx-next {
            .transform(none);
        }

        &.k-fx-start .k-fx-current {
            will-change: transform;
            .transform(none);
        }

        &.k-fx-end .k-fx-current,
        &.k-fx-left.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        &.k-fx-right.k-fx-end .k-fx-current {
            .transform(translatex(-100%));
        }

        &.k-fx-up.k-fx-end .k-fx-current {
            .transform(translatey(100%));
        }

        &.k-fx-down.k-fx-end .k-fx-current {
            .transform(translatey(-100%));
        }
    }
}
