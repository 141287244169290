﻿/* Notification */

.k-notification-wrap
{
    padding: .6em .5em;
    cursor: default;
    position: relative;
    white-space: nowrap;
}

.k-notification-button .k-notification-wrap
{
    padding-right: 20px;
}

.k-notification-wrap > .k-i-note
{
    vertical-align: text-bottom;
    margin-right: 4px;
}

.k-notification-wrap > .k-i-close
{
    position: absolute;
    top: 7px;
    right: 4px;
    display: none;
}

.k-notification-button .k-notification-wrap > .k-i-close
{
    display: block;
}